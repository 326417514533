/* eslint-disable no-console */
import React, { useState, useCallback } from "react";
import {
  Container,
  CotizadorPlazo,
  CotizadorProgrammaticCalculadora,
  CotizadorProgrammaticDuracion,
  CotizadorProgrammaticFrecuencia,
  CotizadorProgrammaticHorasOn,
  CotizadorProgrammaticInventario,
} from "../../components";
import "./styles.scss";

export const CotizadorProgrammatic = () => {
  const [inventarioData, setInventarioData] = useState({
    ascensoresSeleccionados: 0,
    audienciaSeleccionadaAscensor: 0,
    audienciaSeleccionadaTotal: 0,
    audienciaSeleccionadaTotems: 0,
    edificiosSeleccionadosAscensores: 0,
    edificiosSeleccionadosTotems: 0,
    totemsSeleccionados: 0,
  });
  const [plazo, setPlazo] = useState(0);
  const [frecuencia, setFrecuencia] = useState("");
  const [horasSeleccionadas, setHorasSeleccionadas] = useState({
    horas: 0,
    intervalos: [],
    options: [], // Agregar options aquí
  });
  const [duracion, setDuracion] = useState(0);
  const [isProgrammaticSelected, setIsProgrammaticSelected] = useState(false);
  const [isDigitalSelected, setIsDigitalSelected] = useState(false); // Añadido
  const [resetInventario, setResetInventario] = useState(false);

  const handleProgrammaticChange = (isProgrammatic) => {
    setIsProgrammaticSelected(isProgrammatic);
    setIsDigitalSelected(!isProgrammatic); // Deshabilitar digital si se selecciona programmatic
    setResetInventario(true); // Indicar que el inventario debe resetearse

    // Resetear los datos que se pasan a CotizadorProgrammaticCalculadora
    setInventarioData({
      ascensoresSeleccionados: 0,
      audienciaSeleccionadaAscensor: 0,
      audienciaSeleccionadaTotal: 0,
      audienciaSeleccionadaTotems: 0,
      edificiosSeleccionados: 0,
      totemsSeleccionados: 0,
    });
  };

  const handleDigitalChange = (isDigital) => {
    setIsDigitalSelected(isDigital);
    setIsProgrammaticSelected(!isDigital); // Deshabilitar programmatic si se selecciona digital
    setResetInventario(true); // Indicar que el inventario debe resetearse

    // Resetear los datos que se pasan a CotizadorProgrammaticCalculadora
    setInventarioData({
      ascensoresSeleccionados: 0,
      audienciaSeleccionadaAscensor: 0,
      audienciaSeleccionadaTotal: 0,
      audienciaSeleccionadaTotems: 0,
      edificiosSeleccionados: 0,
      totemsSeleccionados: 0,
    });
  };

  const handleResetInventarioComplete = () => {
    setResetInventario(false); // Restablecer el estado después de resetear
  };

  const handleSubmitInventario = (data) => {
    setInventarioData(data);
  };

  const handlePlazoChange = (numberOfDays) => {
    setPlazo(numberOfDays);
  };

  const handleFrecuenciaChange = (selectedFrecuencia) => {
    setFrecuencia(selectedFrecuencia);
  };

  const handleHorasChange = useCallback((totalHours, selectedIntervals, selectedOptions) => {
    setHorasSeleccionadas({ horas: totalHours, intervalos: selectedIntervals, options: selectedOptions });
  }, []);

  const handleDuracionChange = (selectedDuracion) => {
    setDuracion(selectedDuracion);
  };

  return (
    <Container className="Admin" header titulo="">
      <div className="programmatic-container">
        <div className="full-width">
          <CotizadorProgrammaticCalculadora
            inventarioData={inventarioData}
            plazo={plazo}
            frecuencia={frecuencia}
            horasSeleccionadas={horasSeleccionadas}
            duracion={duracion}
            isProgrammaticSelected={isProgrammaticSelected}
          />
        </div>
        <div className="full-width d-flex">
          <CotizadorProgrammaticHorasOn
            onHorasChange={handleHorasChange}
            onProgrammaticChange={handleProgrammaticChange}
            onDigitalChange={handleDigitalChange} // Añadido para manejar cambios en Digital
          />
          <CotizadorProgrammaticInventario
            onSubmit={handleSubmitInventario}
            isProgrammaticSelected={isProgrammaticSelected}
            resetInventario={resetInventario}
            onResetComplete={handleResetInventarioComplete}
          />
          <CotizadorPlazo onPlazoChange={handlePlazoChange} />
          <CotizadorProgrammaticFrecuencia
            onFrecuenciaChange={handleFrecuenciaChange}
            isProgrammaticSelected={isProgrammaticSelected}
            isDigitalSelected={isDigitalSelected} // Pasar el estado a Frecuencia
          />
          <CotizadorProgrammaticDuracion onDuracionChange={handleDuracionChange} />
        </div>
      </div>
    </Container>
  );
};
