/* eslint-disable no-console */
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAuthContext } from "../../context/auth";
import { getIntermediarios } from "../../utils";
import "./styles.scss";

export const ListadoFacturas = ({
  facturas,
  pantallasEdificio,
  comisionEdificio_1_porcentaje,
  comisionEdificio_1_fechainicio,
  comisionEdificio_1_fechafin,
  comisionEdificio_2_porcentaje,
  comisionEdificio_2_fechainicio,
  comisionEdificio_2_fechafin,
  comisionEdificio_3_porcentaje,
  comisionEdificio_3_fechainicio,
  comisionEdificio_3_fechafin,
  edificiosParticipantes,
  pantallasInvolucradas,
}) => {
  const [intermediarios, setIntermediarios] = useState([]);
  const [orden] = useState({ campo: "fechafactura", ascendente: true });
  const [totales, setTotales] = useState({ facturado: 0, comision: 0, comisionCobrada: 0 });

  const { pais } = useAuthContext();

  const localString = useCallback(
    (pais) =>
      ({
        Chile: "es-CL",
        Perú: "es-PE",
        Uruguay: "es-UY",
      })[pais] || "es-UY",
    []
  );

  const moneda = useCallback(
    (pais) =>
      ({
        Chile: "$ ",
        Perú: "S/ ",
        Uruguay: "$ ",
      })[pais] || "$ ",
    []
  );

  const decimales = useCallback(
    (pais) =>
      ({
        Chile: 0,
        Perú: 2,
        Uruguay: 0,
      })[pais] || 0,
    []
  );

  useEffect(() => {
    const fetchIntermediarios = async () => {
      const intermediariosData = await getIntermediarios();
      setIntermediarios(intermediariosData);
    };

    fetchIntermediarios();
  }, []);

  const getFacturadoYComisionNuevo = useCallback(
    (importe, intermediarioNombre, pantallasEdificio, pantallastotales, facturafecha) => {
      let comisionEdificioPorcentaje;

      const fechaInicio1 = new Date(comisionEdificio_1_fechainicio);
      const fechaFin1 = new Date(comisionEdificio_1_fechafin);
      const fechaInicio2 = new Date(comisionEdificio_2_fechainicio);
      const fechaFin2 = new Date(comisionEdificio_2_fechafin);
      const fechaInicio3 = new Date(comisionEdificio_3_fechainicio);
      const fechaFin3 = new Date(comisionEdificio_3_fechafin);

      // Determinar qué comisión se aplica según la fecha de la factura
      if (facturafecha >= fechaInicio1 && facturafecha <= fechaFin1) {
        comisionEdificioPorcentaje = comisionEdificio_1_porcentaje;
      } else if (facturafecha >= fechaInicio2 && facturafecha <= fechaFin2) {
        comisionEdificioPorcentaje = comisionEdificio_2_porcentaje;
      } else if (facturafecha >= fechaInicio3 && facturafecha <= fechaFin3) {
        comisionEdificioPorcentaje = comisionEdificio_3_porcentaje;
      } else {
        // Si la fecha de la factura no está dentro de ningún período de comisión, usar un valor predeterminado
        comisionEdificioPorcentaje = 0;
      }

      // Resto de la función para calcular facturado y comisión
      const intermediarioEncontrado = intermediarios.find((i) => i.nombre === intermediarioNombre && i.pais === pais);
      const comisionIntermediario = intermediarioEncontrado ? intermediarioEncontrado.comision : 0;
      const intermediacion = importe * (comisionIntermediario / 100);
      const inversionNeta = importe - intermediacion;
      const participacionEdificioPorcentaje = pantallasEdificio / pantallastotales;
      const participacionEdificioPlata = participacionEdificioPorcentaje * inversionNeta;
      const comisionEdificio = participacionEdificioPlata * (comisionEdificioPorcentaje / 100);

      return { facturado: participacionEdificioPlata, comision: comisionEdificio };
    },
    [
      intermediarios,
      comisionEdificio_1_porcentaje,
      comisionEdificio_1_fechainicio,
      comisionEdificio_1_fechafin,
      comisionEdificio_2_porcentaje,
      comisionEdificio_2_fechainicio,
      comisionEdificio_2_fechafin,
      comisionEdificio_3_porcentaje,
      comisionEdificio_3_fechainicio,
      comisionEdificio_3_fechafin,
      pais,
    ]
  );

  const facturasOrdenadas = useMemo(
    () =>
      [...facturas].sort((a, b) => {
        const fechaA = new Date(a.facturafecha);
        const fechaB = new Date(b.facturafecha);
        return orden.ascendente ? fechaA - fechaB : fechaB - fechaA;
      }),
    [facturas, orden.ascendente]
  );

  useEffect(() => {
    const newTotales = { facturado: 0, comision: 0, comisionCobrada: 0 };
    facturasOrdenadas.forEach(({ importe, intermediario, pantallastotales, facturafechacobro, facturafecha }) => {
      const { facturado, comision } = getFacturadoYComisionNuevo(
        importe,
        intermediario,
        pantallasEdificio,
        pantallastotales,
        new Date(facturafecha)
      );

      newTotales.facturado += facturado;
      newTotales.comision += comision;
      if (facturafechacobro) {
        newTotales.comisionCobrada += comision;
      }
    });

    setTotales(newTotales);
  }, [facturasOrdenadas, getFacturadoYComisionNuevo, pantallasEdificio]);

  return (
    <div className="table-responsive">
      <table className="table table-fixed table-striped table-bordered table-hover align-middle">
        <thead className="table-dark text-center align-middle">
          <tr>
            <th width="8%" className="numero-factura">
              NÚMERO FACTURA
            </th>
            <th width="8%" className="fecha-factura">
              FECHA FACTURA
            </th>
            <th width="20%" className="cliente">
              CLIENTE
            </th>
            <th width="12%" className="intermediario">
              INTERMEDIARIO
            </th>
            <th width="8%" className="edificios-participantes">
              EDIFICIOS PARTICIPANTES
            </th>
            <th width="8%" className="pantallas-involucradas">
              PARTICIPACIÓN EDIFICIO
            </th>
            <th width="8%" className="importe-neto">
              IMPORTE NETO
            </th>
            <th className="comision" colSpan="2">
              COMISIÓN
            </th>
            <th width="8%" className="cobrada">
              ¿COBRADA?
            </th>
          </tr>
        </thead>

        <tbody>
          {facturasOrdenadas.map((factura) => {
            const {
              cliente,
              edificiostotales,
              facturafecha,
              facturafechacobro,
              facturanumero,
              id,
              importe,
              intermediario,
              pantallastotales,
              plazocobro,
            } = factura;
            const { facturado, comision } = getFacturadoYComisionNuevo(
              importe,
              intermediario,
              pantallasEdificio,
              pantallastotales,
              new Date(facturafecha)
            );

            return (
              <tr className="table-striped" key={id}>
                <td className="text-center">{facturanumero}</td>
                <td className="text-center">
                  {`${new Date(facturafecha).getUTCDate().toString().padStart(2, "0")}/${(new Date(facturafecha).getUTCMonth() + 1).toString().padStart(2, "0")}/${new Date(facturafecha).getUTCFullYear()}`}
                </td>
                <td className="text-center">{cliente}</td>
                <td className="text-center">{intermediario}</td>
                <td className="text-center">{edificiostotales}</td>
                <td className="text-center">
                  {Number(((pantallasEdificio / pantallastotales) * 100).toFixed(1)).toLocaleString(localString(pais), {
                    minimumFractionDigits: 1,
                  })}
                  %
                </td>
                <td className="text-end">{`${moneda(pais)} ${facturado.toLocaleString(localString(pais), {
                  minimumFractionDigits: decimales(pais),
                  maximumFractionDigits: decimales(pais),
                })}`}</td>
                <td className="text-center" width="5%">
                  {(() => {
                    const fechaFactura = new Date(facturafecha);
                    const fechaInicio1 = new Date(comisionEdificio_1_fechainicio);
                    const fechaFin1 = new Date(comisionEdificio_1_fechafin);
                    const fechaInicio2 = new Date(comisionEdificio_2_fechainicio);
                    const fechaFin2 = new Date(comisionEdificio_2_fechafin);
                    const fechaInicio3 = new Date(comisionEdificio_3_fechainicio);
                    const fechaFin3 = new Date(comisionEdificio_3_fechafin);

                    if (fechaFactura >= fechaInicio1 && fechaFactura <= fechaFin1) {
                      return `${comisionEdificio_1_porcentaje}%`;
                    } else if (fechaFactura >= fechaInicio2 && fechaFactura <= fechaFin2) {
                      return `${comisionEdificio_2_porcentaje}%`;
                    } else if (fechaFactura >= fechaInicio3 && fechaFactura <= fechaFin3) {
                      return `${comisionEdificio_3_porcentaje}%`;
                    } else {
                      return "Porcentaje no definido";
                    }
                  })()}
                </td>
                <td className="text-end" width="15%">{`${moneda(pais)} ${comision.toLocaleString(localString(pais), {
                  minimumFractionDigits: decimales(pais),
                  maximumFractionDigits: decimales(pais),
                })}`}</td>
                <td
                  className="text-center"
                  style={{ backgroundColor: facturafechacobro ? "#198754" : "#dc3545", color: "white" }}
                >
                  {facturafechacobro
                    ? "SÍ"
                    : plazocobro
                      ? new Date(plazocobro).toLocaleDateString(localString(pais))
                      : "NO"}
                </td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr className="table-dark">
            <td colSpan="4" className="text-start">
              TOTALES DEL PERÍODO SELECCIONADO
            </td>
            <td colSpan={2} />
            <td className="text-end">
              {moneda(pais)}
              {totales.facturado.toLocaleString(localString(pais), {
                maximumFractionDigits: decimales(pais),
                minimumFractionDigits: decimales(pais),
              })}
            </td>
            <td className="text-center">
              {((totales.comision / totales.facturado) * 100).toLocaleString(localString(pais), {
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
              })}
              %
            </td>
            <td className="text-end">
              {moneda(pais)}
              {totales.comision.toLocaleString(localString(pais), {
                maximumFractionDigits: decimales(pais),
                minimumFractionDigits: decimales(pais),
              })}
            </td>
            <td className="text-end">
              {moneda(pais)}
              {totales.comisionCobrada.toLocaleString(localString(pais), {
                maximumFractionDigits: decimales(pais),
                minimumFractionDigits: decimales(pais),
              })}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};
