/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
import React, { useCallback, useEffect, useState } from "react";
import { FaEdit } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { BlackArrow, Container, Loader } from "..";
import { useAuthContext } from "../../context/auth";
import { getOportunidades, getIntermediarios } from "../../utils";

export const VentasFacturas = () => {
  const [oportunidades, setOportunidades] = useState([]);
  const [intermediarios, setIntermediarios] = useState([]);

  const { pais } = useAuthContext();
  const navigate = useNavigate();

  const goToOportunidad = useCallback((id) => navigate(`/admin/factura/${id}`), [navigate]);

  const localString = useCallback(
    (pais) =>
      ({
        Chile: "es-CL",
        Perú: "es-PE",
        Uruguay: "es-UY",
      }[pais] || "es-UY"),
    []
  );

  const moneda = useCallback(
    (pais) =>
      ({
        Chile: "$",
        Perú: "S/",
        Uruguay: "$",
      }[pais] || "$"),
    []
  );

  const decimales = useCallback(
    (pais) =>
      ({
        Chile: 0,
        Perú: 2,
        Uruguay: 0,
      }[pais] || 0),
    []
  );

  const mesEnLetras = useCallback((mes) => {
    const mesesEnLetras = ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"];
    return mesesEnLetras[parseInt(mes) - 1] || "";
  }, []);

  useEffect(() => {
    const initialize = async () => {
      const allOportunidades = await getOportunidades({ pais });
      const allIntermediarios = await getIntermediarios({ pais });

      // Filtrar oportunidades para incluir solo las facturas emitidas en 2024 o donde fechafactura es NULL
      const oportunidadesFiltradas = allOportunidades.filter((oportunidad) => {
        const year = oportunidad.facturafecha ? new Date(oportunidad.facturafecha).getFullYear() : null;
        return year === 2024 || oportunidad.facturafecha === null && !oportunidad.eliminada;
      });

      const oportunidades100 = oportunidadesFiltradas.filter((oportunidad) => oportunidad.estatus === "100%");

      const oportunidadesConComision = oportunidades100.map((oportunidad) => {
        const intermediarioAsociado = allIntermediarios.find(
          (intermediario) => intermediario.nombre === oportunidad.intermediario
        );
        return { ...oportunidad, comision: intermediarioAsociado ? intermediarioAsociado.comision : 0 };
      });

      setOportunidades(oportunidadesConComision);
      setIntermediarios(allIntermediarios);
    };

    initialize();
  }, [pais]);

  if (oportunidades === undefined || intermediarios === undefined) return <Loader />;

  const calcularTotales = () => {
    let totalVenta = 0;
    let totalComision = 0;
    let totalNeto = 0;
    let totalCobrado = 0;

    oportunidades.forEach(({ importe, facturafechacobro, comision }) => {
      totalVenta += importe || 0; // Sumar el importe de la factura a totalVenta
      totalComision += importe * (comision / 100); // Sumar la comisión de la factura a totalComision

      if (facturafechacobro) {
        totalCobrado += importe || 0; // Sumar el importe de la factura a totalCobrado si facturafechacobro no es NULL
      }
    });

    totalNeto = totalVenta - totalComision; // Calcular el neto restando totalComision de totalVenta

    return {
      totalVenta,
      totalComision,
      totalNeto,
      totalCobrado,
    };
  };

  const totales = calcularTotales();

  const fecha = { day: "2-digit", month: "2-digit", year: "numeric" };

  return (
    <>
      <BlackArrow />
      <Container className="OPORTUNIDADES" header titulo="FACTURACIÓN 2024">
        <div className="table-responsive">
          <table className="table table-hover table-bordered table-sm">
            <thead className="table-dark text-center align-middle">
              <tr>
                <th width="6%">STATUS</th>
                <th width="6%">MES</th>
                <th width="7%">NÚMERO FACTURA</th>
                <th width="8%">FECHA FACTURA</th>
                <th width="20%">CLIENTE PUBLICITARIO</th>
                <th width="10%">VENTA</th>
                <th width="15%">INTERMEDIARIO</th>
                <th width="10%">COMISIÓN {moneda(pais)}</th>
                <th width="10%">NETO</th>
                <th width="8%">COBRADA</th>
                <th width="5%">EDITAR</th>
              </tr>
            </thead>

            <tbody>
              {oportunidades.map(
                ({
                  estatus,
                  mes,
                  cliente,
                  comision,
                  facturafecha,
                  facturafechacobro,
                  intermediario,
                  id,
                  importe,
                  facturanumero,
                }) => (
                  <tr key={id}>
                    <td className="bg-success text-white">{estatus}</td>
                    <td >{mesEnLetras(mes)}</td>
                    <td >{facturanumero ? facturanumero : "-"}</td>
                    <td >
                      {facturafecha ? new Date(facturafecha).toLocaleDateString("es-ES", fecha) : "-"}
                    </td>
                    <td >{cliente}</td>
                    <td className="text-end">{`${moneda(pais)} ${
                      importe?.toLocaleString(localString(pais), {
                        maximumFractionDigits: decimales(pais),
                        minimumFractionDigits: decimales(pais),
                      }) || "error"
                    }`}</td>
                    <td >{intermediario}</td>
                    <td className="text-end">
                      {intermediario === "DIRECTO"
                        ? "-"
                        : `${moneda(pais)} ${(importe * (comision / 100)).toLocaleString(localString(pais), {
                          maximumFractionDigits: decimales(pais),
                          minimumFractionDigits: decimales(pais),
                        })}`}
                    </td>
                    <td className="text-end">{`${moneda(pais)} ${(
                      importe -
                    importe * (comision / 100)
                    ).toLocaleString(localString(pais), {
                      maximumFractionDigits: decimales(pais),
                      minimumFractionDigits: decimales(pais),
                    })}`}</td>
                    <td >{facturafechacobro ? "Sí" : "No"}</td>
                    <td className="text-center align-middle">
                      <FaEdit onClick={() => goToOportunidad(id)} />
                    </td>
                  </tr>
                )
              )}
            </tbody>

            <tfoot>
              <tr className="table-dark">
                <td colSpan="5" className="col-sm-1">
                TOTALES
                </td>
                <td className="text-end">
                  {`${moneda(pais)} ${(totales.totalVenta).toLocaleString(localString(pais), {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                  })}`}
                </td>
                <td />
                <td className="col-1 text-end">
                  {`${moneda(pais)} ${(totales.totalComision).toLocaleString(localString(pais), {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                  })}`}
                </td>
                <td className="col-1 text-end">
                  {`${moneda(pais)} ${(totales.totalNeto).toLocaleString(localString(pais), {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                  })}`}
                </td>
                <td className="col-1 text-end">
                  {`${moneda(pais)} ${(totales.totalCobrado).toLocaleString(localString(pais), {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                  })}`}
                </td>
                <td />
              </tr>
            </tfoot>
          </table>
        </div>
      </Container>
    </>
  );
};
