/* eslint-disable no-console */
import { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import cx from "classix";
import { Container, ListaEdificios, Loader } from "..";
import { useAuthContext } from "../../context/auth";
import { log, getOportunidades, updateOportunidad } from "../../utils";
import "./styles.scss";

export const Oportunidades = () => {
  const { oportunidadId } = useParams();

  const [oportunidadIdentificada, setOportunidadIdentificada] = useState();
  const [showModalConfirmacion, setShowModalConfirmacion] = useState(false);
  const [temporalDataForm, setTemporalDataForm] = useState();
  const [loading, setLoading] = useState(false);

  const { pais } = useAuthContext();

  const methods = useForm();

  const {
    handleSubmit,
    formState: { errors },
    register,
    setValue,
  } = methods;

  const onSubmit = (data) => {
    const currentDate = new Date().toISOString(); // Obtiene la fecha actual en formato ISO
    const newOportunidad = {
      edificiosparticipantes: data.edificiosSeleccionados,
      edificiostotales: Number(data.edificiosTotales),
      pantallastotales: Number(data.pantallasTotales),
      importe: data.importe,
      ejecutivo: data.ejecutivo,
      estatus: data.estatus,
      mes: data.mes,
      updated_at: currentDate,
    };

    setTemporalDataForm(newOportunidad);
    setShowModalConfirmacion(true); // Muestra el modal de confirmación
  };

  const saveButtonHandle = async () => {
    try {
      setLoading(true);
      setShowModalConfirmacion(false);
      await updateOportunidad({ id: oportunidadId, oportunidad: temporalDataForm });
      toast.success("La oportunidad se actualizó correctamente");
      setLoading(false);
    } catch (error) {
      if (error.code === "23505") setError("nombre", { type: "custom", message: "La oportunidad ya existe" });
      toast.error("Hubo un error al actualizar la oportunidad, prueba de nuevo.");
      log.error("saveButtonHandle", error);
      setLoading(false);
      setShowModalConfirmacion(false);
    }
  };

  const months = [
    { value: "01", label: "ENE" },
    { value: "02", label: "FEB" },
    { value: "03", label: "MAR" },
    { value: "04", label: "ABR" },
    { value: "05", label: "MAY" },
    { value: "06", label: "JUN" },
    { value: "07", label: "JUL" },
    { value: "08", label: "AGO" },
    { value: "09", label: "SET" },
    { value: "10", label: "OCT" },
    { value: "11", label: "NOV" },
    { value: "12", label: "DIC" },
  ];

  const selectOptions = months.map((month) => (
    <option key={month.value} value={month.value}>
      {month.label}
    </option>
  ));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const allOportunidades = await getOportunidades({ pais });
        const oportunidadAEditar = allOportunidades.find((op) => op.id === Number(oportunidadId));

        if (oportunidadAEditar) {
          setOportunidadIdentificada(oportunidadAEditar);
          setValue("cliente", oportunidadAEditar.cliente);
          setValue("intermediario", oportunidadAEditar.intermediario);
          setValue("importe", oportunidadAEditar.importe);
          setValue("estatus", oportunidadAEditar.estatus);
          setValue("mes", oportunidadAEditar.mes);
          setValue("ejecutivo", oportunidadAEditar.ejecutivo);
          setValue("edificiosTotales", oportunidadAEditar.edificiostotales);
          setValue("pantallasTotales", oportunidadAEditar.pantallastotales);
          setValue("edificiosparticipantes", oportunidadAEditar.edificiosparticipantes);
        } else {
          console.warn("No se encontró la oportunidad con ID:", oportunidadId);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (oportunidadId && !oportunidadIdentificada) {
      fetchData();
    }
  }, [pais, oportunidadId, setValue, oportunidadIdentificada]);

  return (
    <Container
      className="Container"
      header
      titulo={`EDITAR OPORTUNIDAD #${oportunidadId}: ${oportunidadIdentificada ? oportunidadIdentificada.cliente : ""}`}
    >
      {oportunidadIdentificada ? (
        <FormProvider {...methods}>
          <form className="row g-3 mt-2" onSubmit={handleSubmit(onSubmit)}>
            <div className="col-3 text-start fw-bold">
              <label htmlFor="cliente" className="form-label">
                CLIENTE
              </label>
              <input
                className="form-control input-disabled"
                id="cliente"
                type="text"
                readOnly
                value={oportunidadIdentificada ? oportunidadIdentificada.cliente : ""}
              />
            </div>

            <div className="col-3 text-start fw-bold">
              <label htmlFor="intermediario" className="form-label ">
                INTERMEDIARIO
              </label>
              <input
                className="form-control input-disabled"
                id="cliente"
                type="text"
                readOnly
                value={oportunidadIdentificada ? oportunidadIdentificada.intermediario : ""}
              />
            </div>

            <div className="col-3 text-start fw-bold">
              <label htmlFor="importe" className="form-label">
                TICKET
              </label>
              <input
                className={cx("form-control", errors?.importe && "is-invalid")}
                id="importe"
                defaultValue="importe"
                type="text"
                inputMode="numeric"
                pattern="[0-9]*"
                step="0.01"
                {...register("importe", { required: true, min: 0 })}
              />
              <div className="invalid-feedback">Este campo es obligatorio</div>
            </div>

            <div className="col-3 text-start fw-bold">
              <label htmlFor="estatus" className="form-label">
                STATUS
              </label>
              <select
                className={cx("form-control", errors?.status && "is-invalid")}
                id="estatus"
                {...register("estatus", { required: true })}
              >
                <option value="">Selecciona un porcentaje</option>
                <option value="100%">100%</option>
                <option value="90%">90%</option>
                <option value="75%">75%</option>
                <option value="50%">50%</option>
                <option value="0%">0%</option>
              </select>

              <div className="invalid-feedback">Este campo es obligatorio</div>
            </div>

            <div className="col-3 text-start fw-bold">
              <label htmlFor="mes" className="form-label">
                MES
              </label>
              <select
                className={cx("form-control", errors?.mes && "is-invalid")}
                id="mes"
                type="text"
                {...register("mes", { required: true })}
              >
                <option value="">¿Cuándo se facturará?</option>
                {selectOptions}
              </select>

              <div className="invalid-feedback">Este campo es obligatorio</div>
            </div>

            <div className="col-3 text-start fw-bold">
              <label htmlFor="ejecutivo" className="form-label">
                EJECUTIVO
              </label>
              <select
                className={cx("form-control", errors?.ejecutivo && "is-invalid")}
                id="ejecutivo"
                {...register("ejecutivo", { required: true })}
              >
                <option value="">Responsable de la oportunidad</option>
                {pais === "Perú" && (
                  <>
                    <option value="Santiago">Santiago</option>
                    <option value="Daniela">Daniela</option>
                    <option value="Ignacio">Ignacio</option>
                  </>
                )}
                {pais === "Chile" && (
                  <>
                    <option value="Gerencia">Gerencia</option>
                    <option value="Ángeles">Ángeles</option>
                  </>
                )}
                {pais === "Uruguay" && (
                  <>
                    <option value="Diego">Diego</option>
                    <option value="Alejandro">Alejandro</option>
                    <option value="Federico">Federico</option>
                    <option value="Agustín">Agustín</option>
                    <option value="Ignacio">Ignacio</option>
                  </>
                )}
              </select>
              <div className="invalid-feedback">Este campo es obligatorio</div>
            </div>

            <div className="col-3 text-start fw-bold">
              <label htmlFor="edificiosTotales" className="form-label">
                EDIFICIOS
              </label>
              <input
                className={cx("form-control", errors?.edificiosTotales && "is-invalid")}
                defaultValue={0}
                disabled
                id="edificiosTotales"
                type="number"
                {...register("edificiosTotales", { required: true })}
              />
            </div>

            <div className="col-3 text-start fw-bold">
              <label htmlFor="pantallasTotales" className="form-label">
                PANTALLAS
              </label>
              <input
                className={cx("form-control", errors?.pantallasTotales && "is-invalid")}
                defaultValue={0}
                disabled
                id="pantallasTotales"
                type="number"
                {...register("pantallasTotales", { required: true })}
              />
            </div>

            <ListaEdificios edificiosPreseleccionados={oportunidadIdentificada.edificiosparticipantes} />

            <input hidden type="text" {...register("personasTotales")} />
            <input hidden type="text" {...register("edificiosSeleccionados")} />

            <div className="col-12 d-flex justify-content-center">
              <button className="btn btn-primary mb-5" type="submit" disabled={loading || showModalConfirmacion}>
                {!loading && !showModalConfirmacion ? (
                  "ACTUALIZAR"
                ) : (
                  <>
                    <Spinner size="sm">GUARDANDO...</Spinner>
                    <span> GUARDANDO</span>
                  </>
                )}
              </button>
            </div>
          </form>

          <Modal isOpen={showModalConfirmacion} toggle={() => setShowModalConfirmacion(false)}>
            <ModalHeader>¿Está seguro?</ModalHeader>
            <ModalBody>Se está por actualizar la oportunidad</ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={() => setShowModalConfirmacion(false)}>
                Cancelar
              </Button>
              <Button color="primary" onClick={saveButtonHandle}>
                Guardar
              </Button>
            </ModalFooter>
          </Modal>
        </FormProvider>
      ) : (
        <Loader />
      )}
    </Container>
  );
};
