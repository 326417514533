import { useMemo } from "react";
import cx from "classix";

export const LoginFeedback = ({ status, className }) => {
  const message = useMemo(
    () => ({
      sending_link: "Enviando magic link...",
      check_email:
        "Estamos OK. Por favor revisa tu correo para el login. Te llegará un mail 'noreply' de nuestra base de datos SUPABASE con el link para el acceso :)",
    }),
    []
  );

  return <div className={cx("LoginFeedback", className)}>{message[status]}</div>;
};
