/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
/* eslint-disable indent */
/* eslint-disable no-console */
import React, { useState, useEffect, useCallback } from "react";
import { useAuthContext } from "../../context/auth";
import { getEdificiosOperativos } from "../../utils";
import "./styles.scss";

export const CotizadorProgrammaticInventario = ({
  onSubmit,
  isProgrammaticSelected,
  resetInventario,
  onResetComplete,
}) => {
  const [edificios, setEdificios] = useState([]);
  const [edificiosSeleccionados, setEdificiosSeleccionados] = useState(new Set());
  const [ascensoresSeleccionados, setAscensoresSeleccionados] = useState(0);
  const [totemsSeleccionados, setTotemsSeleccionados] = useState(0);
  const [audienciaSeleccionadaAscensor, setAudienciaSeleccionadaAscensor] = useState(0);
  const [audienciaSeleccionadaTotems, setAudienciaSeleccionadaTotems] = useState(0);
  const [audienciaEdificiosAscensores, setAudienciaEdificiosAscensores] = useState(new Set());
  const [audienciaEdificiosTotems, setAudienciaEdificiosTotems] = useState(new Set());
  const [, setAudienciaSeleccionadaTotal] = useState(0);
  const [selectAllAscensores, setSelectAllAscensores] = useState(false);
  const [selectAllTotems, setSelectAllTotems] = useState(false);

  const { pais } = useAuthContext();

  useEffect(() => {
    const initialize = async () => {
      let newEdificios = await getEdificiosOperativos({ pais });

      if (isProgrammaticSelected) {
        newEdificios = newEdificios.filter((edificio) => edificio.programatica === "SÍ");
      }

      newEdificios.sort((a, b) => {
        if (a.edificio && b.edificio) {
          return a.edificio.localeCompare(b.edificio);
        }
        return 0;
      });

      setEdificios(newEdificios);
    };

    initialize();
  }, [pais, isProgrammaticSelected]);

  // Definición de updateAudienciaAscensor
  const updateAudienciaAscensor = useCallback(() => {
    let newAudienciaAscensor = 0;

    audienciaEdificiosAscensores.forEach((id) => {
      const edificio = edificios.find((e) => e.id === id && e.pantallas > 0);
      if (edificio) {
        newAudienciaAscensor += edificio.personas;
      }
    });

    setAudienciaSeleccionadaAscensor(newAudienciaAscensor);

    updateAudienciaTotal(); // Llama a la función para actualizar la audiencia total
  }, [audienciaEdificiosAscensores, edificios]);

  // useEffect para actualizar audienciaSeleccionadaAscensor cuando cambie audienciaEdificiosAscensores
  useEffect(() => {
    updateAudienciaAscensor();
  }, [audienciaEdificiosAscensores]);

  useEffect(() => {
    if (resetInventario) {
      // Reseteo completo de inventario cuando se selecciona o deselecciona 'Programmatic'
      setEdificiosSeleccionados(new Set()); // Desmarcar todos los edificios
      setAscensoresSeleccionados(0); // Resetear ascensores seleccionados
      setTotemsSeleccionados(0); // Resetear tótems seleccionados
      setAudienciaSeleccionadaAscensor(0); // Resetear audiencia de ascensores
      setAudienciaSeleccionadaTotems(0); // Resetear audiencia de tótems
      setAudienciaEdificiosAscensores(new Set()); // Resetear el set de audiencia de ascensores
      setAudienciaEdificiosTotems(new Set()); // Resetear el set de audiencia de tótems
      setSelectAllAscensores(false); // Desmarcar el checkbox de 'Seleccionar Todos' para ascensores
      setSelectAllTotems(false); // Desmarcar el checkbox de 'Seleccionar Todos' para tótems

      // Forzar desmarcado de todos los checkboxes
      // eslint-disable-next-line prettier/prettier
      const uncheckAll = document.querySelectorAll(".inventario input[type=\"checkbox\"]");
      uncheckAll.forEach((checkbox) => {
        checkbox.checked = false;
      });

      // Notificar que el reset ha sido completado
      onResetComplete();
    }
  }, [isProgrammaticSelected, resetInventario, onResetComplete]);

  const updateAudienciaTotal = useCallback(() => {
    const ascensoresIds = new Set(
      [...audienciaEdificiosAscensores].filter((id) => {
        const edificio = edificios.find((e) => e.id === id);
        return edificio && edificio.pantallas > 0;
      })
    );

    const totemsIds = new Set(
      [...audienciaEdificiosTotems].filter((id) => {
        const edificio = edificios.find((e) => e.id === id);
        return edificio && edificio.totems > 0;
      })
    );

    let totalAudiencia = 0;
    const allIds = new Set([...ascensoresIds, ...totemsIds]);

    allIds.forEach((id) => {
      const edificio = edificios.find((e) => e.id === id);
      if (edificio) {
        totalAudiencia += edificio.personas;
      }
    });

    setAudienciaSeleccionadaTotal(totalAudiencia);

    onSubmit({
      ascensoresSeleccionados,
      audienciaSeleccionadaAscensor,
      audienciaSeleccionadaTotems,
      audienciaSeleccionadaTotal: totalAudiencia, // Aquí aseguramos que no se duplique la audiencia
      edificiosSeleccionadosAscensores: [...ascensoresIds],
      edificiosSeleccionadosTotems: [...totemsIds],
      totemsSeleccionados,
    });
  }, [
    audienciaEdificiosAscensores,
    audienciaEdificiosTotems,
    edificios,
    ascensoresSeleccionados,
    totemsSeleccionados,
    audienciaSeleccionadaAscensor,
    audienciaSeleccionadaTotems,
    onSubmit,
  ]);

  useEffect(() => {
    updateAudienciaTotal();
  }, [audienciaEdificiosAscensores, audienciaEdificiosTotems]);

  const handleCheckboxChange = (pantallas, isChecked, tipo, personas, id) => {
    const newEdificiosSeleccionados = new Set(edificiosSeleccionados);

    if (isChecked) {
      newEdificiosSeleccionados.add(id);
    } else {
      newEdificiosSeleccionados.delete(id);
    }

    setEdificiosSeleccionados(newEdificiosSeleccionados);

    if (tipo === "ascensores") {
      setAscensoresSeleccionados((prevPantallas) => {
        const newAscensores = isChecked ? prevPantallas + pantallas : Math.max(prevPantallas - pantallas, 0);
        const newAudienciaAscensor = isChecked
          ? audienciaSeleccionadaAscensor + personas
          : Math.max(audienciaSeleccionadaAscensor - personas, 0);

        setAudienciaSeleccionadaAscensor(newAudienciaAscensor);
        setAudienciaEdificiosAscensores((prevSet) => {
          const newSet = new Set(prevSet);
          if (isChecked) {
            newSet.add(id);
          } else {
            newSet.delete(id);
          }
          return newSet;
        });

        updateAudienciaTotal();
        return newAscensores;
      });
    } else if (tipo === "totems") {
      setTotemsSeleccionados((prevPantallas) => {
        const newTotems = isChecked ? prevPantallas + pantallas : Math.max(prevPantallas - pantallas, 0);
        const newAudienciaTotems = isChecked
          ? audienciaSeleccionadaTotems + personas
          : Math.max(audienciaSeleccionadaTotems - personas, 0);

        setAudienciaSeleccionadaTotems(newAudienciaTotems);
        setAudienciaEdificiosTotems((prevSet) => {
          const newSet = new Set(prevSet);
          if (isChecked) {
            newSet.add(id);
          } else {
            newSet.delete(id);
          }
          return newSet;
        });

        updateAudienciaTotal();
        return newTotems;
      });
    }
  };

  const handleSelectAllChange = (isChecked, tipo) => {
    const newEdificiosSeleccionados = new Set();

    if (isChecked) {
      edificios.forEach((edificio) => newEdificiosSeleccionados.add(edificio.id));
    }

    setEdificiosSeleccionados(newEdificiosSeleccionados);

    if (tipo === "ascensores") {
      const newPantallas = isChecked
        ? edificios.reduce((total, edificio) => total + (edificio.pantallas || 0), 0) // Solo sumar si tiene pantallas (ascensores)
        : 0;

      const newAudienciaAscensor = isChecked
        ? edificios.reduce((total, edificio) => total + (edificio.pantallas > 0 ? edificio.personas : 0), 0) // Solo sumar la audiencia de edificios con pantallas (ascensores)
        : 0;

      setSelectAllAscensores(isChecked);
      setAscensoresSeleccionados(newPantallas);
      setAudienciaSeleccionadaAscensor(newAudienciaAscensor);

      // Para evitar duplicaciones, restablecer el set y recalcular audiencia total
      const newSet = new Set();
      if (isChecked) {
        edificios.forEach((edificio) => {
          if (edificio.pantallas > 0) {
            // Asegurarse de que el edificio tenga pantallas
            newSet.add(edificio.id);
          }
        });
      }
      setAudienciaEdificiosAscensores(newSet);

      updateAudienciaTotal();
    } else if (tipo === "totems") {
      const newPantallas = isChecked
        ? edificios.reduce(
            (total, edificio) => total + (edificio.totems || 0), // Asegúrate de sumar solo los edificios con tótems
            0
          )
        : 0;

      const newAudienciaTotems = isChecked
        ? edificios.reduce((total, edificio) => total + (edificio.totems ? edificio.personas : 0), 0)
        : 0;

      setSelectAllTotems(isChecked);
      setTotemsSeleccionados(newPantallas);
      setAudienciaSeleccionadaTotems(newAudienciaTotems);

      // Manejar el conjunto de audiencia solo para los edificios con tótems
      const newSet = new Set();
      if (isChecked) {
        edificios.forEach((edificio) => {
          if (edificio.totems > 0) {
            newSet.add(edificio.id);
          }
        });
      }
      setAudienciaEdificiosTotems(newSet);

      updateAudienciaTotal();
    }
  };

  function sumarPantallas(edificios) {
    let suma = 0;
    for (let i = 0; i < edificios.length; i++) {
      suma += edificios[i].pantallas;
    }
    return suma;
  }

  function sumarTotems(edificios) {
    let suma = 0;
    for (let i = 0; i < edificios.length; i++) {
      suma += edificios[i].totems;
    }
    return suma;
  }

  return (
    <div className="inventario">
      <p className="tituloinventario">INVENTARIO</p>
      <hr />
      <p className="subtitulo">ASCENSORES</p>
      <hr />
      <div className="checkbox">
        <input
          type="checkbox"
          style={{ width: "15px", height: "15px", marginRight: "10px" }}
          checked={selectAllAscensores}
          onChange={(e) => handleSelectAllChange(e.target.checked, "ascensores")}
        />
        <span style={{ fontWeight: "bold" }}>{sumarPantallas(edificios)} | SELECCIONAR TODOS</span>
      </div>
      {!selectAllAscensores && edificios.length > 0 && (
        <div>
          {edificios
            .filter((edificio) => edificio.pantallas > 0)
            .map((edificio) => (
              <div key={edificio.id} className="checkbox">
                <input
                  type="checkbox"
                  style={{ width: "15px", height: "15px", marginRight: "10px" }}
                  onChange={(e) =>
                    handleCheckboxChange(
                      edificio.pantallas,
                      e.target.checked,
                      "ascensores",
                      edificio.personas,
                      edificio.id
                    )
                  }
                />
                <span>
                  {edificio.pantallas} | {edificio.edificio.toUpperCase()}
                </span>
              </div>
            ))}
        </div>
      )}
      {edificios.length === 0 && !selectAllAscensores && <p>Cargando edificios...</p>}

      {edificios.some((edificio) => edificio.totems > 0) && (
        <div>
          <hr />
          <p className="subtitulo">CIRCULACIÓN</p>
          <hr />
          <div className="checkbox">
            <input
              type="checkbox"
              style={{ width: "15px", height: "15px", marginRight: "10px" }}
              checked={selectAllTotems}
              onChange={(e) => handleSelectAllChange(e.target.checked, "totems")}
            />
            <span style={{ fontWeight: "bold" }}>{sumarTotems(edificios)} | SELECCIONAR TODOS</span>
          </div>
          {!selectAllTotems && edificios.length > 0 && (
            <div>
              {edificios
                .filter((edificio) => edificio.totems !== null)
                .map((edificio) => (
                  <div key={edificio.id} className="checkbox">
                    <input
                      type="checkbox"
                      style={{ width: "15px", height: "15px", marginRight: "10px" }}
                      onChange={(e) =>
                        handleCheckboxChange(
                          edificio.totems,
                          e.target.checked,
                          "totems",
                          edificio.personas,
                          edificio.id
                        )
                      }
                    />
                    <span>
                      {edificio.totems} | {edificio.edificio.toUpperCase()}
                    </span>
                  </div>
                ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
