import { useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { ImCheckboxChecked, ImCheckboxUnchecked } from "react-icons/im";
import cx from "classix";
import { Loader } from "..";
import { useAuthContext } from "../../context/auth";
import { getEdificiosOperativos } from "../../utils";
import "./styles.scss";

export const ListaEdificios = ({ forceSubmit, edificiosPreseleccionados }) => {
  const [edificios, setEdificios] = useState();
  const [checkAll, setCheckAll] = useState(false);

  const { pais } = useAuthContext();

  const localString = useCallback(
    (pais) =>
      ({
        Chile: "es-CL",
        Perú: "es-PE",
        Uruguay: "es-UY",
      })[pais] || "es-UY",
    []
  );

  const { getValues, register, setValue, watch } = useFormContext();

  const allCheckbox = watch();

  const actualizarStringEdificios = useCallback((edificiosSeleccionados, id, agregar) => {
    edificiosSeleccionados = String(edificiosSeleccionados);

    if (agregar) return edificiosSeleccionados === "" ? id : `${edificiosSeleccionados},${id}`;
    else
      return edificiosSeleccionados
        .split(",")
        .filter((n) => n !== id.toString())
        .join(",");
  }, []);

  useEffect(() => {
    const initialize = async () => {
      const newEdificios = await getEdificiosOperativos({ pais });
      setEdificios(newEdificios.sort((a, b) => a.edificio.localeCompare(b.edificio)));
      setValue("edificiosTotales", "0");
      setValue("pantallasTotales", "0");
      setValue("totemsTotales", "0");
      setValue("personasTotales", "0");
      setValue("edificiosSeleccionados", "");
    };

    initialize();
  }, [setValue, edificiosPreseleccionados, pais]);

  const toggleCheck = useCallback(
    (id, newStatus) => {
      const names = {
        check: `edificio-${id}-checkbox`,
        edificiosTotales: "edificiosTotales",
        pantallas: `edificio-${id}-pantallas`,
        totems: `edificio-${id}-totems`,
        pantallasTotales: "pantallasTotales",
        totemsTotales: "totemsTotales",
        personas: `edificio-${id}-personas`,
        personasTotales: "personasTotales",
        edificiosSeleccionados: "edificiosSeleccionados",
      };

      const values = getValues([
        names.check,
        names.edificiosTotales,
        names.pantallas,
        names.totems,
        names.pantallasTotales,
        names.totemsTotales,
        names.personas,
        names.personasTotales,
        names.edificiosSeleccionados,
      ]);

      const newCheckValue = newStatus !== undefined ? newStatus : !values[0];
      setValue(names.check, newCheckValue);

      const selectedEdificios = newCheckValue ? Number(values[1]) + 1 : Number(values[1]) - 1;

      // Sumar pantallas y tótems
      const selectedPantallas = newCheckValue
        ? Number(values[3]) + Number(values[2]) + Number(values[4])
        : Number(values[3]) - (Number(values[2]) + Number(values[4]));

      const selectedTotems = newCheckValue
        ? Number(values[5]) + Number(values[4])
        : Number(values[5]) - Number(values[4]);

      setValue(names.edificiosTotales, selectedEdificios < 0 ? 0 : selectedEdificios);
      setValue(names.pantallasTotales, selectedPantallas < 0 ? 0 : selectedPantallas);
      setValue(names.totemsTotales, selectedTotems < 0 ? 0 : selectedTotems);

      const newPersonasTotalesValue = newCheckValue
        ? Number(values[7]) + Number(values[6])
        : Number(values[7]) - Number(values[6]);
      setValue(names.personasTotales, newPersonasTotalesValue);

      const newEdificiosSeleccionados = actualizarStringEdificios(values[8], id, newCheckValue);
      setValue(names.edificiosSeleccionados, newEdificiosSeleccionados);

      if (newStatus === undefined && checkAll === true) setCheckAll(false);

      forceSubmit && forceSubmit();
    },
    [getValues, setValue, forceSubmit, actualizarStringEdificios, checkAll]
  );

  const toggleCheckAll = useCallback(() => {
    if (!checkAll) {
      let totalEdificios = 0;
      let totalPantallas = 0;
      let totalTotems = 0;

      edificios.forEach(({ id, pantallas, totems }) => {
        toggleCheck(id, true);
        totalEdificios++;
        totalPantallas += Number(pantallas) + Number(totems); // Suma de pantallas y tótems
        totalTotems += Number(totems); // Solo suma los tótems
      });

      setValue("edificiosTotales", totalEdificios);
      setValue("pantallasTotales", totalPantallas); // Total de pantallas y tótems
      setValue("totemsTotales", totalTotems);
      setCheckAll(true);
    } else {
      edificios.forEach(({ id }) => toggleCheck(id, false));
      setValue("edificiosTotales", 0);
      setValue("pantallasTotales", 0);
      setValue("totemsTotales", 0);
      setCheckAll(false);
    }
  }, [edificios, toggleCheck, checkAll, setValue]);

  useEffect(() => {
    const initialize = async () => {
      const newEdificios = await getEdificiosOperativos({ pais });
      setEdificios(newEdificios.sort((a, b) => a.edificio.localeCompare(b.edificio)));
      setValue("edificiosTotales", 0);
      setValue("pantallasTotales", 0);
      setValue("totemsTotales", 0);
      setValue("personasTotales", 0);
      setValue("edificiosSeleccionados", "");

      if (edificiosPreseleccionados && edificiosPreseleccionados.trim() !== "") {
        const preseleccionadosArray = edificiosPreseleccionados.split(",").map(Number);
        if (preseleccionadosArray.length > 0) {
          preseleccionadosArray.forEach((id) => toggleCheck(id, true));
        }
      }
    };

    initialize();
  }, [setValue, edificiosPreseleccionados, pais]);

  if (edificios === undefined) return <Loader />;

  return (
    <div style={{ width: "75%", margin: "0 auto", marginTop: "3rem" }}>
      <table className="table table-striped table-hover table-sm">
        <thead className="table-dark">
          <tr>
            <th scope="col" className="text-center" onClick={toggleCheckAll}>
              {checkAll ? (
                <ImCheckboxChecked className="icon" size={18} />
              ) : (
                <ImCheckboxUnchecked className="icon" size={18} />
              )}
            </th>
            <th scope="col" className="text-center">
              EDIFICIO
            </th>
            <th scope="col" className="text-center">
              UBICACIÓN
            </th>
            <th scope="col" className="text-center">
              ASCENSORES
            </th>
            <th scope="col" className="text-center">
              CIRCULACIÓN
            </th>
            <th scope="col" className="text-center">
              PERSONAS ÚNICAS
            </th>
          </tr>
        </thead>

        <tbody>
          {edificios.map(({ created_at, edificio, id, pantallas, totems, personas, ubicacion }) => (
            <tr
              key={created_at}
              onClick={() => toggleCheck(id)}
              className={cx(allCheckbox[`edificio-${id}-checkbox`] === true && "table-success")}
            >
              <td className="text-center">
                {allCheckbox[`edificio-${id}-checkbox`] ? (
                  <ImCheckboxChecked className="icon" size={18} />
                ) : (
                  <ImCheckboxUnchecked className="icon" size={18} />
                )}
                <input hidden type="checkbox" value={edificio} {...register(`edificio-${id}-checkbox`)} />
                <input hidden type="text" value={pantallas} {...register(`edificio-${id}-pantallas`)} />
                <input hidden type="text" value={totems} {...register(`edificio-${id}-totems`)} />
                <input hidden type="text" value={personas} {...register(`edificio-${id}-personas`)} />
              </td>
              <td className="text-center">{edificio.toUpperCase()}</td>
              <td className="text-center">{ubicacion}</td>
              <td className="text-center">{pantallas > 0 ? pantallas : "-"}</td>
              <td className="text-center">{totems > 0 ? totems : "-"}</td>
              <td className="text-center">{personas.toLocaleString(localString(pais))}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
