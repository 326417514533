/* eslint-disable no-console */
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import cx from "classix";
import { Container, LoginFeedback } from "../../components";
import { useAuthContext } from "../../context/auth";
import { log, login, getUsers } from "../../utils";
import "./styles.scss";

export const Login = () => {
  const [status, setStatus] = useState("form");
  const [formVisible, setFormVisible] = useState(true);
  const [usuarios, setUsuarios] = useState([]);

  const { pais } = useAuthContext();

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm();

  useEffect(() => {
    const fetchUsuarios = async () => {
      try {
        const users = await getUsers();
        setUsuarios(users);
      } catch (error) {
        log.error("Error fetching users", error);
      }
    };

    fetchUsuarios();
  }, []);

  const onSubmit = useCallback(
    async ({ email }) => {
      try {
        const usuario = usuarios.find((user) => user.email === email);

        if (!usuario || !usuario.habilitado) {
          setStatus("invalid_email");
          setStatus((prevStatus) => {
            console.log("New status 1:", prevStatus);
            return prevStatus;
          });
          return;
        }

        setStatus("sending_link");
        setFormVisible(false); // Oculta el formulario después de enviar
        setStatus((prevStatus) => prevStatus);
        const result = await login({ email, pais });
        if (result) setStatus("check_email");
        else setStatus("form");
        setStatus((prevStatus) => prevStatus);
      } catch (error) {
        log.error("Login onSubmit", error);
        setStatus("form");
      }
    },
    [pais, usuarios]
  );

  useEffect(() => {
    if (status === "invalid_email") {
      const timer = setTimeout(() => {
        setStatus("form");
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [status]);

  return (
    <Container className="Login" header titulo="INICIAR SESIÓN">
      {formVisible && (
        <form className="row g-3 justify-content-md-center" onSubmit={handleSubmit(onSubmit)}>
          <div className="col-6">
            <input
              className={cx("form-control", errors?.email && "is-invalid")}
              id="email"
              placeholder="Ingrese su correo"
              type="email"
              {...register("email", { required: true })}
            />
            {status === "invalid_email" && (
              <div style={{ color: "red", marginTop: "1rem" }}>El correo ingresado no está autorizado</div>
            )}
          </div>

          <div className="col-12 text-center">
            <button className="botonLogin">ENVIAR</button>
          </div>
        </form>
      )}
      <div className="col-12 text-center">{status !== "form" && <LoginFeedback status={status} />}</div>
    </Container>
  );
};
